import React, { useEffect, useState } from "react";
import { ELogLevel, Loader } from "./App";
import axios from "axios";
type Props = {
  setLogLevel: (x: number) => void;
  logLevel: ELogLevel;
  logText: string;
  hideLink: boolean;
  setLogCategoryFilter: (category: string) => void;
  // warningCount: number;
  // errorCount: number;
};
export const Toolbar = (props: Props) => {
  const [hash, setHash] = useState("");
  const [isGettingLink, setIsGettingLink] = useState(false);
  const [logCategories, setLogCategories] = useState([] as string[]);

  useEffect(() => {
    const uniqueLogCategories =
      props.logText
        .match(/\bLog\S+\b(?=:)/g)
        ?.filter((cat, index, self) => self.indexOf(cat) === index) || [];
    setLogCategories(uniqueLogCategories);
  }, [hash, props.logText]);

  const onClickGetLink = async () => {
    if (hash) {
      navigator.clipboard.writeText(`https://logviewer.xyz/${hash}`);
      return;
    }
    setIsGettingLink(true);

    try {
      const res = await axios.post("/api/upload_log", {
        logText: props.logText,
      });
      setHash(res.data);
    } catch (error) {
      if (error.response.data) {
        alert(error.response.data.message);
      } else {
        alert(error.message);
      }
    }
    setIsGettingLink(false);
  };

  const renderLogCategoryDropdown = () => {
    if (!logCategories.length) return;
    return (
      <>
        Log Category:
        <select
          style={{
            padding: 8,
            fontSize: 14,
          }}
          // value={props.logLevel}
          name="logCategoryFilter"
          id="logCategoryFilter"
          onChange={(e) => props.setLogCategoryFilter(e.target.value)}
        >
          <option value={""}>Show All</option>

          {logCategories.map((category) => (
            <option value={category}>{category}</option>
          ))}
        </select>
      </>
    );
  };
  return (
    <div
      style={{
        paddingLeft: "5em",
        background: "#0000005e",
        padding: 12,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        gap: 8,
      }}
    >
      {/* <img
        className="icon"
        alt="Open log file"
        src="icons/OpenFile.svg"
        style={{
          width: 32,
          height: 32,
          color: "white",
          marginRight: 10,
          cursor: "pointer",
          fill: "#fff",
        }}
      /> */}
      <div style={{ width: "5em" }} />
      log Level:{" "}
      <select
        style={{
          // background: "#7a7d9469",
          // color: "#ddd",
          padding: 8,
          fontSize: 14,
        }}
        value={props.logLevel}
        name="logLevel"
        id="logLevel"
        onChange={(e) => props.setLogLevel(+e.target.value)}
      >
        <option value={ELogLevel.info}>Show All</option>
        <option value={ELogLevel.warning}>Warning</option>
        <option value={ELogLevel.error}>Error</option>
      </select>
      {renderLogCategoryDropdown()}
      {props.logText.length > 0 && !props.hideLink && (
        <button
          title={hash ? "Copy link" : "Get link"}
          onClick={onClickGetLink}
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: 14,
            userSelect: "text",
            padding: 8,
            width: 220,
            overflow: "hidden",
            whiteSpace: "nowrap",
            border: "1px solid black",
            // background: "#7a7d9469",
            cursor: "pointer",
            // color: "#ddd",
          }}
        >
          <div style={{ overflow: "hidden", width: 200, marginRight: 4 }}>
            {hash.length ? (
              `https://logviewer.xyz/${hash}`
            ) : isGettingLink ? (
              <Loader size={12} thickness={4} color="#111" />
            ) : (
              "share"
            )}
          </div>
          {hash.length > 0 && (
            <img
              style={{ width: 14, height: 14, cursor: "pointer" }}
              alt="Copy link"
              src="icons/copy.svg"
            />
          )}
        </button>
      )}
      {/* <span className="warning">{props.warningCount} Warnings</span> */}
      {/* <span className="error">{props.errorCount} Errors</span> */}
    </div>
  );
};
