import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { Toolbar } from "./Toolbar";

export enum ELogLevel {
  info,
  warning,
  error,
}
function App() {
  const [logText, setLogText] = useState("");
  const [logLevel, setLogLevel] = useState(ELogLevel.info as number);
  const [isLoading, setIsLoading] = useState(false);
  const [hideLink, setHideLink] = useState(false);
  const [logCategoryFilter, setLogCategoryFilter] = useState("");
  const logWindow = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.addEventListener("paste", onPaste);
    const hash = getHashFromUrl();
    if (hash.length) {
      fetchLog(hash);
    }
    return function cleanup() {
      document.removeEventListener("paste", onPaste);
    };
  }, []);

  useEffect(() => {
    if (logWindow.current) {
      logWindow.current.innerHTML = highlightText(
        filterByLogLevel(filterByLogCategory(logText))
      )
        .split("\n")
        .join("<br/>");
    }
  });

  const fetchLog = async (hash: string) => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `https://log-viewer.nyc3.cdn.digitaloceanspaces.com/${hash}`
      );
      if (res.data.length) {
        setLogText(res.data);
        setHideLink(true);
      }
    } catch (error) {
      if (error.response.data)
        alert(error.response.data.message || error.response.data);
      else alert(error.code);
    }
    setIsLoading(false);
  };
  const onPaste = (e: any) => {
    const pastedText = (
      e.clipboardData || (window as any).clipboardData
    ).getData("text");
    setLogText(pastedText);
    setHideLink(false);
  };

  const filterByLogCategory = (text: string) => {
    if (!logCategoryFilter) return text;
    const filter = new RegExp(`.*${logCategoryFilter}.+(\r\n|\r|\n)`, "g");
    return text.match(filter)?.join("") || "";
  };
  const filterByLogLevel = (text: string) => {
    let filter;
    switch (logLevel) {
      case ELogLevel.warning:
        filter = /.*(( Error:| Warning:)(.|\r?\n|)+?(?=(\[\d|$|Log.+:)))/g;
        break;
      case ELogLevel.error:
        filter = /.*(( Error:)(.|\r?\n|)+?(?=(\[\d|$|Log.+:)))/g;
        break;
      default:
        return text;
    }
    return text.match(filter)?.join("") || "";
  };

  const onDrop = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    if (file === undefined) return;
    var reader = new FileReader();

    reader.onload = function (e2) {
      const newLogText: any = e2.target?.result || "";
      setLogText(newLogText);
      setHideLink(false);
    };

    reader.readAsText(file);
  };
  const onDragOver = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };
  const render = () => {
    if (!logText.length)
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <br />
          {isLoading ? (
            <Loader size={256} thickness={32} color="#2a2f31" />
          ) : (
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                className="icon"
                alt="Open log file"
                src="icons/dropFile.svg"
                style={{
                  width: 300,
                  height: 300,
                }}
              />
              <span style={{ color: "#6f737b" }}>
                Drag and drop a log file here or paste its contents
              </span>
            </div>
          )}

          <br />
        </div>
      );

    return (
      <div
        className="logWindow"
        ref={logWindow}
        suppressContentEditableWarning={true}
      ></div>
    );
  };

  return (
    <div className="App" onDrop={onDrop} onDragOver={onDragOver}>
      <Toolbar
        // errorCount={errorCount}
        // warningCount={warningCount}
        logText={logText}
        logLevel={logLevel}
        setLogLevel={setLogLevel}
        setLogCategoryFilter={setLogCategoryFilter}
        hideLink={hideLink}
      />
      {render()}
    </div>
  );
}

export default App;

function highlightText(text: string) {
  const res = text
    .replace(/>/g, "&gt")
    .replace(/</g, "&lt")
    .replace(/\b(\d+)/g, `<span class="number">$1</span>`)
    .replace(/'(.*?)'/g, `<span class="string">'$1'</span>`)
    .replace(/Error:/g, `<span class="error">Error:</span>`)
    .replace(/Warning:/g, `<span class="warning">Warning:</span>`)
    .replace(/Log(\w+:)/g, `<span class="logCat">Log$1</span>`)
    .replace(/]Log(\w+:)/g, `]<span class="logCat">Log$1</span>`);
  return res;
}

export function getHashFromUrl() {
  return window.location.pathname.split("/")[1];
}

export const Loader = (props: {
  size: number;
  thickness: number;
  color: string;
}) => {
  const { size, thickness, color } = props;
  const style = {
    width: size,
    height: size,
    borderWidth: thickness,
    borderColor: `${color} transparent transparent transparent`,
  };
  return (
    <div
      className="lds-ring"
      style={{
        width: size,
        height: size,
      }}
    >
      <div style={style} />
      <div style={style} />
      <div style={style} />
      <div style={style} />
    </div>
  );
};
